// import React from 'react'

// const Project = () => {
//   return (
//     <div className='reveal' style={{width:"80%",margin:"auto"}}>
//         <h2 style={{color:"#0057A7",textAlign:"center",fontSize:40}}>Latest Projects</h2>
//         <div style={{display:"flex",justifyContent:"space-evenly"}}>
//             <div className='zoom' style={{width:"300px",height:"200px",borderRadius:20,overflow:"hidden"}}>
//                 <img  style={{width:"100%",objectFit:"cover"}} alt='banking' src='https://www.certfee.com/wp-content/uploads/2021/07/open-banking-fintech-small-business-lending.jpg'/>
//             </div>
           
//             <div className='zoom' style={{width:"300px",height:"200px",borderRadius:20,overflow:"hidden"}}>
//                 <img style={{width:"100%",objectFit:"cover"}} alt='banking' src='https://media.istockphoto.com/id/1426832181/photo/cancer-patient-see-doctor-in-a-hospital.jpg?s=612x612&w=0&k=20&c=uV0nXpvP0T_ErBrjzb5V5iFcUrVTUAXcbtcrllTO4Ig='/>
//             </div>
//             <div className='zoom' style={{width:"300px",height:"200px",borderRadius:20,overflow:"hidden"}}>
//                 <img style={{width:"100%",objectFit:"cover"}} alt='banking' src='https://media.istockphoto.com/id/2169276561/photo/a-closeup-picture-of-a-fresh-tender-tea-leaves-ready-for-harvest-in-a-tea-plantation.webp?a=1&b=1&s=612x612&w=0&k=20&c=xAShYpkWS-34My9VG36nsX6cIBXY3eYobtMafthBatY='/>
//             </div>
//         </div>
//     </div>
//   )
// }

// export default Project

import React from 'react';

const projects = [
  {
    name: "Banking Software",
    description: "A secure and scalable banking solution with features for online transactions, customer management, and reports.",
    technologies: "React, Flask ,Firebase",
    repo: "https://github.com/your-repo",
    demo: "https://your-demo-link.com",
    img:'https://www.certfee.com/wp-content/uploads/2021/07/open-banking-fintech-small-business-lending.jpg'
  },
  {
    name: "Doctor Appointment System",
    description: "A web application to schedule doctor appointments, manage patient records, and track medical histories.",
    technologies: "React, Firebase",
    repo: "https://github.com/your-repo",
    demo: "https://your-demo-link.com",
    img:'https://media.istockphoto.com/id/1426832181/photo/cancer-patient-see-doctor-in-a-hospital.jpg?s=612x612&w=0&k=20&c=uV0nXpvP0T_ErBrjzb5V5iFcUrVTUAXcbtcrllTO4Ig='
  },
  {
    name: "FPC Application",
    description: "A platform for farmers and traders to connect, bid, and access agricultural services and financial support.",
    technologies: "React,Firebase",
    repo: "https://github.com/your-repo",
    demo: "https://your-demo-link.com",
    img:'https://media.istockphoto.com/id/2169276561/photo/a-closeup-picture-of-a-fresh-tender-tea-leaves-ready-for-harvest-in-a-tea-plantation.webp?a=1&b=1&s=612x612&w=0&k=20&c=xAShYpkWS-34My9VG36nsX6cIBXY3eYobtMafthBatY='
  }
];

const Project = () => {
  return (
         <div className='reveal' style={{width:"70%",margin:"auto"}}>
         <h2 style={{color:"#0057A7",textAlign:"center",fontSize:40}}>Latest Projects</h2>
      <div className="project- list">
        {projects.map((project, index) => (
        <div style={{display:"flex",margin:"20px 0",alignItems:"center",justifyContent:"center",width:"100%"}}>
           <div className='zoom' style={{width:"30%" ,borderRadius:20,overflow:"hidden"}}>
                <img style={{width:"100%",objectFit:"cover"}} alt='banking' src={project.img}/>
            </div>
          <div className="project-card" key={index} style={{padding:20,width:"60%"}}>
            <h3>{project.name}</h3>
            <p>{project.description}</p>
            <p><strong>Technologies Used:</strong> {project.technologies}</p>
            <a href={project.repo} target="_blank" rel="noopener noreferrer">View Code</a> | 
            <a href={project.demo} target="_blank" rel="noopener noreferrer">Live Demo</a>
          </div>
          </div>
        ))}
      </div>
      </div>
  );
}

export default Project;
